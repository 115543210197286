import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, colors, Box, Button } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  }
}));

const data = [
  {
    icon: 'fas fa-search',
    title: 'Intelligent search',
    subtitle:
      <span>Intelligent search makes check-in <span style={{ color: 'rgb(255,100,83)' }}><b>4 times faster!</b></span></span>
  },
  {
    icon: 'fas fa-qrcode',
    title: 'QR code',
    subtitle:
      <span>Make your check-in <span style={{ color: 'rgb(255,100,83)' }}><b>instantaneous</b></span> with QR code scanning</span>
  },
  {
    icon: 'fas fa-wifi',
    title: 'Real-time update',
    subtitle:
      <span>Parallelize check-in with <span style={{ color: 'rgb(255,100,83)' }}><b>multiples devices</b></span> that update in real time</span>
  },
];

const FastCheckIn = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        // subtitle={<Box mt={5}><span className={classes.textWhite}>Give your guests a super fast <span className={classes.textOrange}>check-in</span> experience they will <span className={classes.textOrange}>remember</span> !</span></Box>}
        titleVariant="h3"
        subtitleVariant='h4'
        title={<div><div className={classes.textOrange}>Say goodbye to waiting lines! </div>{' '}<span className={classes.textWhite}>Instant check-in is the future.</span></div>}
        ctaGroup={[
          <Box
            mt={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container spacing={isMd ? 4 : 7}>
              {data.map((item, index) => (
                <Grid key={index} item xs={12} sm={4} data-aos={'fade-up'}>
                  <DescriptionListIcon
                    title={item.title}
                    subtitle={item.subtitle}
                    icon={
                      <IconAlternate
                        fontIconClass={item.icon}
                        size="medium"
                        color={colors.indigo}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <Box mt={4}>
              <Button variant="outlined" color="secondary" size="large" href="https://guestlist-backoffice.qeerio.com/application/event-list" aria-label="Get started now">
                Get started now
              </Button>
            </Box>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

FastCheckIn.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default FastCheckIn;
