export const reviews = [
  {
    authorPhoto: {
      src: 'https://media-exp1.licdn.com/dms/image/C4E03AQGGeyTyWtnLrQ/profile-displayphoto-shrink_400_400/0/1538123140563?e=1633564800&v=beta&t=BfZRI378nGL2bs_6jXZ0kOiPVahUnFYzJJeJgMgM-N0',
      srcSet: 'https://media-exp1.licdn.com/dms/image/C4E03AQGGeyTyWtnLrQ/profile-displayphoto-shrink_400_400/0/1538123140563?e=1633564800&v=beta&t=BfZRI378nGL2bs_6jXZ0kOiPVahUnFYzJJeJgMgM-N0'
    },
    authorName: 'Charles-Louis de Lovinfosse',
    authorOccupation: 'Guns for Hire, Artistic Director & Event Director',
    feedback:
      'Qeerio is incredibly easy to get started with. We use it on all our events for many well-known brands and completely trust it!'
  },
  {
    authorPhoto: {
      src: 'https://media-exp1.licdn.com/dms/image/C4D03AQH5dXb7NKv6uA/profile-displayphoto-shrink_400_400/0/1603139582630?e=1634169600&v=beta&t=HfbdMLy5tyuRz7pvfVRC903m6psDAnF5sexhMqrFoxw',
      srcSet: 'https://media-exp1.licdn.com/dms/image/C4D03AQH5dXb7NKv6uA/profile-displayphoto-shrink_400_400/0/1603139582630?e=1634169600&v=beta&t=HfbdMLy5tyuRz7pvfVRC903m6psDAnF5sexhMqrFoxw'
    },
    authorName: 'Annabelle Mekari',
    authorOccupation: 'Profirst, Project Coordinator',
    feedback:
      'The time saved before and after the event for the check-in management is insane. The affordable prices and guest satisfaction makes it a no brainer.'
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Customizing the application theme for each one of our clients is a game changer! We always get great feedback from our clients and hostesses.'
  },
];
