/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Seo from '../../seo/guestlist';
import Home from 'views/guestlist/Home';
import Main from 'layouts/Guestlist';
import WithLayout from 'WithLayout';

const AgencyPage = () => {
  return (
    <WithLayout
      seo={Seo}
      component={Home}
      layout={Main}
    />
  )
};

export default AgencyPage;
