import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Box, Button, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  },
  macImage: {
    width: '101%'
  },
  iphoneImage: {
    width: '100%'
  }
}));

const FastCheckIn = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        subtitle={<div><span className={classes.textOrange}>Save valuable time</span>{' '}<span className={classes.textWhite}>on your check-in planning</span></div>}
        subtitleVariant="h4"
        titleVariant="h3"
        title={<Box mt={1}><span className={classes.textWhite}>Get your check-in <span className={classes.textOrange}>ready</span> in seconds</span></Box>}
        ctaGroup={[
          <Box
            mt={10}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container spacing={7} justify="center" alignItems="center">
              <Grid key={1} item xs={12} sm={6} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/screen4.png'
                    alt="Mac image"
                    className={classes.macImage}
                    loading="lazy"
                  />
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={2}>
                    <Typography variant="h5">
                      <span>1. Setup your <span style={{ color: 'rgb(255,100,83)' }}><b>guest list</b></span></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary">
                        Create an event and upload your guest excel sheet
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={6} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/simpletablet.png'
                    alt="Phone Image"
                    className={classes.iphoneImage}
                    loading="lazy"
                  />
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={2}>
                    <Typography variant="h5">
                      <span>2. Start your <span style={{ color: 'rgb(255,100,83)' }}><b>check-in</b></span></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary">
                        Download the application and start checking your guests in
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mt={10}>
              <Button variant="outlined" color="secondary" size="large" href="https://guestlist-backoffice.qeerio.com/guestlist" aria-label="Get started now">
                Get started now
              </Button>
            </Box>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

FastCheckIn.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default FastCheckIn;
